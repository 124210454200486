import { Routes } from 'constants/routes.constants';
import { Links } from 'types/link.types';

import { Item } from 'components/@footer/FooterSection/types';

export const getAbout = (links?: Links) => {
  if (!links) return;

  const items = [];

  if (links.digicelGroup) {
    items.push({
      labelId: 'footer.link.digicel_group.title',
      url: links.digicelGroup,
    });
  }

  if (links.digicelInternational) {
    items.push({
      labelId: 'footer.link.digicel_international.title',
      url: links.digicelInternational,
    });
  }

  if (links.digicelFoundation) {
    items.push({
      labelId: 'footer.link.digicel_foundation.title',
      url: links.digicelFoundation,
    });
  }

  return items;
};

export const getApps = (links?: Links) => {
  if (!links) return;

  const items = [];

  if (links.digicelInternationalApp) {
    items.push({
      labelId: 'footer.link.digicel_international_app.title',
      url: links.digicelInternationalApp,
    });
  }

  if (links.digicelInternationalCallApp) {
    items.push({
      labelId: 'footer.link.digicel_international_call_app.title',
      url: links.digicelInternationalCallApp,
    });
  }

  if (links.myDigicelApp) {
    items.push({
      labelId: 'footer.link.my_digicel_app.title',
      url: links.myDigicelApp,
    });
  }

  if (links.sportsMaxApp) {
    items.push({
      labelId: 'footer.link.sportx_max_app.title',
      url: links.sportsMaxApp,
    });
  }

  if (links.bipApp) {
    items.push({
      labelId: 'footer.link.bip_app.title',
      url: links.bipApp,
    });
  }

  if (links.goLoudApp) {
    items.push({
      labelId: 'footer.link.go_loud_app.title',
      url: links.goLoudApp,
    });
  }

  return items;
};

export const getUseful = (
  senderCountry: string,
  receiverCountry: string,
  links?: Links,
  country?: string,
) => {
  if (!links) return;

  const items: Array<Item> = [];

  if (links.digicelMarketHome) {
    items.push({
      labelId: 'footer.link.digicel_market_home.title',
      labelValues: { market: country },
      url: links.digicelMarketHome,
    });
  }

  if (links.eStore) {
    items.push({
      labelId: 'footer.link.e_store.title',
      url: links.eStore,
    });
  }

  items.push({
    labelId: 'footer.link.esim.title',
    url: 'https://esim.digicelgroup.com/',
  });

  items.push({
    labelId: 'label.promotions',
    url: Routes.Promotions,
    query: {
      receiverCountry,
      senderCountry,
    },
  });

  if (senderCountry === 'us') {
    items.push({
      labelId: 'footer.link.digicel_send_money.title',
      url: Routes.SendMoney,
    });

    items.push({
      labelId: 'footer.link.sms_updates.title',
      url: Routes.OptIn,
    });
  }

  return items;
};

export const getSupport = (links?: Links) => {
  if (!links) return;

  const items = [];

  if (links.support) {
    items.push({
      labelId: 'footer.link.support.title',
      url: links.support,
    });
  }

  if (links.faq) {
    items.push({
      labelId: 'footer.link.faq.title',
      url: '/faq',
    });
  }

  if (links.contact) {
    items.push({
      labelId: 'footer.link.contact.title',
      url: links.contact,
    });
  }

  return items;
};

export const getSocial = (links?: Links) => {
  return {
    facebook: links?.facebook,
    twitter: links?.twitter,
    youtube: links?.youtube,
    instagram: links?.instagram,
  };
};
