import { camelizeKeys } from 'humps';

import linksJson from 'assets/json/links.json' assert { type: 'json' };

import { Links } from 'types/link.types';

const COUNTRY_LINKS = camelizeKeys(linksJson) as {
  [key: string]: Links | undefined;
};

export const getLinks = (country?: string) => {
  if (!country) return;

  const links = COUNTRY_LINKS[country.toLowerCase()];

  return links;
};
