import { MenuItem, Select } from '@mui/material';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { testId } from 'utils/test-id.utils';

import {
  COOKIES_KEY_LOCALE,
  COOKIES_MAX_AGE_LANGUAGE,
} from 'constants/storage.constants';
import { useLocale } from 'hooks/useLocale';
import { useLocales } from 'hooks/useLocales';

const LanguageSelector = () => {
  const locale = useLocale();
  const locales = useLocales();
  const router = useRouter();

  const [currLanguage, setCurrLanguage] = useState<string>(locale);

  useEffect(() => {
    setCurrLanguage(locale);
  }, [locale]);

  if (!locales.length) {
    return null;
  }

  const handleChangeLocale = (locale: string) => {
    setCurrLanguage(locale);
    setCookie(COOKIES_KEY_LOCALE, locale, {
      maxAge: COOKIES_MAX_AGE_LANGUAGE,
      secure: true,
      sameSite: 'strict',
    });

    router.replace(router.asPath, router.asPath, { locale });
  };

  return (
    <Select
      fullWidth
      value={currLanguage}
      onChange={(e) => handleChangeLocale(e.target.value)}
      {...testId('language-selector')}
    >
      {locales.map((l) => (
        <MenuItem key={l} value={l} {...testId(`language-selector-${l}`)}>
          <FormattedMessage id={`general.language.${l}`} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
