import { Box, BoxProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Link from 'components/@common/Link';

import { Item } from './types';

interface Props {
  titleId: string;
  items?: Array<Item>;
  props?: BoxProps;
}

const FooterSectionDesktop = ({ titleId, items, props }: Props) => {
  return (
    <Box {...props}>
      <Typography variant="h4" component="p" sx={{ mb: 6 }}>
        <FormattedMessage id={titleId} />
      </Typography>

      {items && (
        <Box display="flex" flexDirection="column">
          {items.map((item, idx) => (
            <Link
              href={{
                pathname: item.url,
                query: item.query,
              }}
              key={item.labelId}
              underline="hover"
              prefetch={false}
              target={item.url.includes('http') ? '_blank' : undefined}
              rel={
                item.url.includes('http') ? undefined : 'noreferrer noopener'
              }
              color="secondary"
              sx={{ mb: idx < items.length - 1 ? 3 : 0 }}
            >
              <FormattedMessage id={item.labelId} values={item.labelValues} />
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FooterSectionDesktop;
