import dynamic from 'next/dynamic';

export default {
  Vector: dynamic(() => import('./Vector')),
  DigicelLogomark: dynamic(() => import('./DigicelLogomark')),
  DigicelWordmark: dynamic(() => import('./DigicelWordmark')),
  IcoAccount: dynamic(() => import('./IcoAccount')),
  IcoAmex: dynamic(() => import('./IcoAmex')),
  IcoApplePay: dynamic(() => import('./IcoApplePay')),
  IcoArrowBack: dynamic(() => import('./IcoArrowBack')),
  IcoCancel: dynamic(() => import('./IcoCancel')),
  IcoCheckGradient: dynamic(() => import('./IcoCheckGradient')),
  IcoCheck: dynamic(() => import('./IcoCheck')),
  IcoChecked: dynamic(() => import('./IcoChecked')),
  IcoChevronRight: dynamic(() => import('./IcoChevronRight')),
  IcoChevron: dynamic(() => import('./IcoChevron')),
  IcoClose: dynamic(() => import('./IcoClose')),
  IcoDelete: dynamic(() => import('./IcoDelete')),
  IcoDinersClub: dynamic(() => import('./IcoDinersClub')),
  IcoDropdown: dynamic(() => import('./IcoDropdown')),
  IcoFacebookButton: dynamic(() => import('./IcoFacebookButton')),
  IcoFacebook: dynamic(() => import('./IcoFacebook')),
  IcoFailedGradient: dynamic(() => import('./IcoFailedGradient')),
  IcoFailed: dynamic(() => import('./IcoFailed')),
  IcoGooglePay: dynamic(() => import('./IcoGooglePay')),
  IcoInstagram: dynamic(() => import('./IcoInstagram')),
  IcoLeft: dynamic(() => import('./IcoLeft')),
  IcoMastercard: dynamic(() => import('./IcoMastercard')),
  IcoMenu: dynamic(() => import('./IcoMenu')),
  IcoMoneyBill: dynamic(() => import('./IcoMoneyBill')),
  IcoPaypal: dynamic(() => import('./IcoPaypal')),
  IcoProcessingGradient: dynamic(() => import('./IcoProcessingGradient')),
  IcoProcessing: dynamic(() => import('./IcoProcessing')),
  IcoProfile: dynamic(() => import('./IcoProfile')),
  IcoProgress: dynamic(() => import('./IcoProgress')),
  IcoReceipt: dynamic(() => import('./IcoReceipt')),
  IcoRenew: dynamic(() => import('./IcoRenew')),
  IcoReplay: dynamic(() => import('./IcoReplay')),
  IcoRight: dynamic(() => import('./IcoRight')),
  IcoSearch: dynamic(() => import('./IcoSearch')),
  IcoStarHalf: dynamic(() => import('./IcoStarHalf')),
  IcoStar: dynamic(() => import('./IcoStar')),
  IcoSuccess: dynamic(() => import('./IcoSuccess')),
  IcoTwitter: dynamic(() => import('./IcoTwitter')),
  IcoUnchecked: dynamic(() => import('./IcoUnchecked')),
  IcoVisa: dynamic(() => import('./IcoVisa')),
  IcoWarning: dynamic(() => import('./IcoWarning')),
  IcoYoutube: dynamic(() => import('./IcoYoutube')),
  IcoFlash: dynamic(() => import('./IcoFlash')),
  IcoHandshake: dynamic(() => import('./IcoHandshake')),
  RiaLogoSm: dynamic(() => import('./RiaLogoSm')),
};
