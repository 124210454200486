import { Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Routes } from 'constants/routes.constants';

import Icon from 'components/@common/Icon';
import Link from 'components/@common/Link';

interface Props {
  minimized?: boolean;
}

const BottomLinksComp = ({ minimized }: Props) => {
  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      alignItems={{ xs: 'center', md: 'center' }}
      justifyContent={{ md: 'space-between' }}
      gap={4}
      sx={{ mt: minimized ? 0 : { xs: 4, sm: 10 } }}
    >
      <Typography variant="body1">
        <FormattedMessage id="footer.bottom.copyright.label" />
      </Typography>
      <Stack
        gap={4}
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
      >
        <Link underline="always" href={Routes.PrivacyPolicy}>
          <FormattedMessage id="footer.link.privacy_policy.title" />
        </Link>
        <Link underline="always" href={Routes.CookieNotice}>
          <FormattedMessage id="footer.link.cookie_notice.title" />
        </Link>
        <Link underline="always" href={Routes.TermsAndConditions}>
          <FormattedMessage id="footer.link.terms_and_conditions.title" />
        </Link>
        <Link underline="always" href={Routes.TermsOfUse}>
          <FormattedMessage id="footer.link.terms_of_use.title" />
        </Link>
      </Stack>
    </Stack>
  );
};

export const BottomLinks = memo(BottomLinksComp);

interface SocialProps {
  links: {
    facebook?: string;
    twitter?: string;
    youtube?: string;
    instagram?: string;
  };
}

const SocialIconsComp = ({ links }: SocialProps) => {
  return (
    <Stack direction="row" gap={4}>
      {links.facebook && (
        <Link
          href={links.facebook}
          color="brand.darkBlue"
          aria-label="Facebook"
          sx={{
            '&:hover': {
              opacity: 0.5,
            },
          }}
        >
          <Icon name="IcoFacebook" fill="currentColor" fontSize={40} />
        </Link>
      )}
      {links.instagram && (
        <Link
          href={links.instagram}
          color="brand.darkBlue"
          aria-label="Instagram"
          sx={{
            '&:hover': {
              opacity: 0.5,
            },
          }}
        >
          <Icon name="IcoInstagram" fill="currentColor" fontSize={40} />
        </Link>
      )}
      {links.youtube && (
        <Link
          href={links.youtube}
          color="brand.darkBlue"
          aria-label="Youtube"
          sx={{
            '&:hover': {
              opacity: 0.5,
            },
          }}
        >
          <Icon name="IcoYoutube" fill="currentColor" fontSize={40} />
        </Link>
      )}
      {links.twitter && (
        <Link
          href={links.twitter}
          color="brand.darkBlue"
          aria-label="Twitter"
          sx={{
            '&:hover': {
              opacity: 0.5,
            },
          }}
        >
          <Icon name="IcoTwitter" fill="currentColor" fontSize={40} />
        </Link>
      )}
    </Stack>
  );
};

export const SocialIcons = memo(SocialIconsComp);
