import dynamic from 'next/dynamic';

// For some reason some icons are not build correctly with svg build
export default {
  IcoCheckedGradientRound: dynamic(() => import('./IcoCheckGradientRound')),
  IcoVisa: dynamic(() => import('./IcoVisa')),
  IcoMastercard: dynamic(() => import('./IcoMastercard')),
  IcoAmex: dynamic(() => import('./IcoAmex')),
  IcoGooglePay: dynamic(() => import('./IcoGooglePay')),
  IcoApplePay: dynamic(() => import('./IcoApplePay')),
  IcoPaypal: dynamic(() => import('./IcoPaypal')),
  IcoDinersClub: dynamic(() => import('./IcoDinersClub')),
};
