import { useCallback, useMemo } from 'react';

import { useCountries } from 'hooks/useCountries';

export const useIsDomestic = () => {
  const { senderCountry, countries } = useCountries();

  const checkIsDomestic = useCallback(
    (country?: string) => {
      return countries.some(
        (c) => c.codeAlpha2.toLowerCase() === country?.toLowerCase(),
      );
    },
    [countries],
  );

  const isSenderDomestic = useMemo(
    () => checkIsDomestic(senderCountry),
    [checkIsDomestic, senderCountry],
  );

  return {
    isSenderDomestic,
    checkIsDomestic: checkIsDomestic,
  };
};
