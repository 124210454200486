import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  BoxProps,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/@common/Icon';
import Link from 'components/@common/Link';

import { Item } from './types';

interface Props {
  titleId: string;
  items?: Array<Item>;
  props?: BoxProps;
}

const FooterSectionMobile = ({ titleId, items, props }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box {...props}>
      <Accordion expanded={expanded} onChange={handleAccordionClick}>
        <AccordionSummary
          expandIcon={<Icon name="IcoChevron" fontSize={30} />}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            p: 0,
            [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
              [`&.${accordionSummaryClasses.expanded}`]: {
                transform: 'rotate(180deg)',
              },
            },
            [`& .${accordionSummaryClasses.content}`]: {
              m: 0,
            },
          }}
        >
          <Typography variant="h4" component="p" sx={{ mb: 6 }}>
            <FormattedMessage id={titleId} />
          </Typography>
        </AccordionSummary>

        {items && (
          <AccordionDetails sx={{ p: 0 }}>
            <Box display="flex" flexDirection="column">
              {items.map((item, idx) => (
                <Link
                  href={item.url}
                  key={item.labelId}
                  underline="hover"
                  target={item.url.includes('http') ? '_blank' : undefined}
                  rel={
                    item.url.includes('http')
                      ? undefined
                      : 'noreferrer noopener'
                  }
                  color="secondary"
                  sx={{ mb: idx < items.length - 1 ? 3 : 0 }}
                >
                  <FormattedMessage
                    id={item.labelId}
                    values={item.labelValues}
                  />
                </Link>
              ))}
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default FooterSectionMobile;
