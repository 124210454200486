import { Box, Container, Grid, NoSsr } from '@mui/material';
import { getName } from 'i18n-iso-countries';

import { getLinks } from 'utils/link.utils';

import { useCountries } from 'hooks/useCountries';
import { useIsDomestic } from 'hooks/useIsDomestic';
import { useLocale } from 'hooks/useLocale';

import FooterSection from 'components/@footer/FooterSection';
import LanguageSelector from 'components/@footer/LanguageSelector';

import { BottomLinks, SocialIcons } from './Footer.components';
import {
  getAbout,
  getApps,
  getSocial,
  getSupport,
  getUseful,
} from './Footer.utils';

interface Props {
  minimized?: boolean;
}

const Footer: React.FC<Props> = ({ minimized }) => {
  const locale = useLocale();
  const { checkIsDomestic } = useIsDomestic();
  const { senderCountry, receiverCountry } = useCountries();

  const links = getLinks(
    checkIsDomestic(senderCountry) ? senderCountry : 'international',
  );

  const aboutLinks = getAbout(links);
  const appLinks = getApps(links);
  const usefulLinks = getUseful(
    senderCountry,
    receiverCountry,
    links,
    senderCountry ? getName(senderCountry, locale) : undefined,
  );
  const supportLinks = getSupport(links);
  const socialLinks = getSocial(links);

  return (
    <NoSsr>
      <Box
        component="footer"
        bgcolor="support.neutral01"
        pb={minimized ? 12 : 10}
        pt={minimized ? 12 : 16}
      >
        <Container maxWidth="lg">
          {!minimized && (
            <Grid
              container
              columnSpacing={2}
              rowSpacing={{ xs: 10, md: 0 }}
              justifyContent="space-between"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md="auto"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {aboutLinks && (
                  <FooterSection
                    titleId="footer.section.about.title"
                    items={aboutLinks}
                  />
                )}
                <Box
                  mt={8}
                  display={{ xs: 'none', sm: 'block' }}
                  maxWidth={150}
                >
                  <LanguageSelector />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md="auto">
                {appLinks && (
                  <FooterSection
                    titleId="footer.section.apps.title"
                    items={appLinks}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md="auto">
                {usefulLinks && (
                  <FooterSection
                    titleId="footer.section.useful.title"
                    items={usefulLinks}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md="auto"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {supportLinks && (
                  <FooterSection
                    titleId="footer.section.support.title"
                    items={supportLinks}
                  />
                )}
                <Box mt={8} display={{ xs: 'none', sm: 'block' }}>
                  <SocialIcons links={socialLinks} />
                </Box>
              </Grid>
              <Grid item xs={12} display={{ xs: 'block', sm: 'none' }}>
                <FooterSection titleId="footer.section.social.title" />
                <SocialIcons links={socialLinks} />
                <Box my={4}>
                  <LanguageSelector />
                </Box>
              </Grid>
            </Grid>
          )}
          <BottomLinks minimized={minimized} />
        </Container>
      </Box>
    </NoSsr>
  );
};

export default Footer;
