import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgDigicelLogomark = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 121 154" {...props}>
    <path d="M0.00778053 36.2811C0.526309 36.2811 0.940201 36.2811 1.35642 36.2811C40.6739 36.2811 79.9936 36.2858 119.311 36.2602C120.395 36.2602 120.755 36.5018 120.753 37.6497C120.713 52.5091 120.802 67.3685 120.704 82.2255C120.632 93.0883 118.783 103.619 113.844 113.424C105.874 129.255 93.0429 138.882 75.857 142.85C70.2137 144.154 64.4704 144.647 58.6898 144.495C47.4798 144.205 36.8302 141.747 27.1898 135.805C14.3661 127.903 6.5138 116.336 2.56787 101.971C1.25411 97.1895 0.342615 92.3122 0.261231 87.3537C0.0868386 76.7186 0.070562 66.0812 0.0147562 55.4438C-0.0154718 49.5233 0.0101058 43.6027 0.0101058 37.6822V36.2788L0.00778053 36.2811ZM24.0856 61.7244V63.2789C24.0856 69.6247 23.9577 75.9751 24.1368 82.3162C24.2344 85.769 24.5274 89.2846 25.304 92.6399C28.0595 104.574 35.2933 112.562 47.1311 116.08C55.7089 118.629 64.4006 118.566 73.0249 116.331C81.2888 114.189 87.7995 109.546 92.0244 102.02C95.3588 96.0811 96.5633 89.5727 96.6633 82.8506C96.7609 76.3515 96.6842 69.8501 96.6842 63.3487C96.6842 62.2674 96.137 61.726 95.0426 61.7244C71.9181 61.7244 48.7913 61.7244 25.6668 61.7244H24.0856Z" />
    <path d="M36.8814 9.8386C36.8721 15.1387 32.4425 19.5768 27.1316 19.6047C21.8185 19.6349 17.2563 15.1062 17.2563 9.79909C17.2563 4.45715 21.751 -0.0111014 27.1084 0.00284015C32.5076 0.0144581 36.8883 4.42695 36.879 9.8386H36.8814Z" />
    <path d="M103.388 9.86418C103.358 15.2781 98.914 19.6674 93.5288 19.6024C88.111 19.5373 83.8325 15.1387 83.8674 9.66668C83.9023 4.262 88.3295 -0.055232 93.7799 0.00053422C99.107 0.0563005 103.416 4.48041 103.388 9.86185V9.86418Z" />
  </svg>
);
const Memo = memo(SvgDigicelLogomark);
export default Memo;
