import { BoxProps, Theme, useMediaQuery } from '@mui/material';

import FooterSectionDesktop from './FooterSectionDesktop';
import FooterSectionMobile from './FooterSectionMobile';
import { Item } from './types';

interface Props {
  titleId: string;
  items?: Array<Item>;
  props?: BoxProps;
}

const FooterSection = (props: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  return isMobile && !!props?.items?.length ? (
    <FooterSectionMobile {...props} />
  ) : (
    <FooterSectionDesktop {...props} />
  );
};

export default FooterSection;
