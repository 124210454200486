import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgDigicelWordmark = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 364 154" {...props}>
    <g clipPath="url(#clip0_9447_74553)">
      <path d="M-0.130371 35.0728C0.288172 35.0542 0.595104 35.0287 0.902035 35.0287C12.2469 35.038 23.601 34.794 34.9365 35.1309C46.5604 35.4771 56.8914 39.3924 65.0391 48.0362C70.1569 53.4664 73.2611 59.9701 74.6446 67.2616C76.6048 77.5876 75.8793 87.679 71.1986 97.222C66.0599 107.699 57.5053 114.131 46.4139 117.344C41.7425 118.697 36.9525 119.275 32.0905 119.273C21.8292 119.266 11.5679 119.273 1.30662 119.273H-0.125716V35.0752L-0.130371 35.0728ZM17.6833 51.7516C17.6554 52.1884 17.6182 52.4905 17.6182 52.7949C17.6158 69.0066 17.6228 85.216 17.5949 101.428C17.5949 102.392 17.9391 102.599 18.811 102.592C22.8011 102.559 26.7912 102.652 30.779 102.55C33.2414 102.487 35.7364 102.364 38.1454 101.904C46.9231 100.222 52.9874 95.2725 55.6521 86.6101C57.5983 80.2876 57.5146 73.8838 55.6963 67.5474C54.0663 61.8685 50.6552 57.5164 45.3885 54.7165C41.5588 52.681 37.4013 51.8422 33.1159 51.7376C28.3538 51.6215 23.5894 51.7005 18.825 51.7005C18.4832 51.7005 18.1413 51.7307 17.6833 51.7539V51.7516Z" />
      <path d="M118.747 123.309C121.514 124.64 124.074 126.114 126.804 127.134C132.61 129.301 138.609 130.061 144.657 128.207C150.261 126.488 153.695 121.766 153.879 115.89C153.914 114.814 153.884 113.736 153.884 112.407C153.456 112.709 153.17 112.86 152.942 113.076C148.954 116.831 144.134 118.494 138.747 118.848C132.692 119.243 127.151 117.783 122.186 114.333C115.627 109.774 111.818 103.386 110.369 95.621C108.684 86.5845 110.114 78.0965 115.343 70.3914C122.679 59.5821 137.161 55.8365 148.652 61.8546C150.275 62.705 151.68 63.976 153.186 65.0518C153.393 65.2005 153.605 65.3423 154.016 65.6281V60.6672H170.488C170.488 61.1296 170.488 61.5455 170.488 61.9614C170.488 79.6045 170.563 97.2475 170.453 114.891C170.407 122.242 168.317 129.013 163.313 134.616C159.057 139.379 153.6 142.135 147.396 143.278C136.519 145.281 126.067 143.873 116.157 138.865C115.262 138.415 114.394 137.885 113.594 137.288C113.318 137.081 113.053 136.46 113.167 136.193C115.004 131.857 116.903 127.547 118.752 123.316L118.747 123.309ZM140.697 103.379C143.316 103.465 145.766 102.887 147.994 101.495C152.035 98.9716 154.123 95.219 154.488 90.5649C154.923 85.0417 153.449 80.1784 148.71 76.8301C142.978 72.7824 134.57 73.9767 130.136 79.3303C128.192 81.6771 127.23 84.4074 126.939 87.3885C126.06 96.3878 132.217 103.593 140.697 103.377V103.379Z" />
      <path d="M325.577 95.6419H285.113C285.576 97.9075 286.453 99.7989 287.78 101.481C290.389 104.783 293.961 106.138 298.025 106.323C303.336 106.565 308.066 104.888 312.307 101.741C312.521 101.583 312.76 101.458 313.067 101.267C316.695 104.372 320.32 107.471 323.987 110.608C321.419 113.652 318.411 115.869 314.932 117.365C305.533 121.406 295.854 122.168 286.211 118.622C276.022 114.874 270.225 107.169 268.309 96.6016C267.007 89.4124 267.588 82.3254 270.66 75.6172C274.815 66.5459 281.956 61.2086 291.752 59.4822C298.032 58.3762 304.192 58.9106 309.991 61.7082C318.573 65.8465 323.296 72.9567 325.258 82.0722C326.161 86.2616 326.247 90.5021 325.784 94.7566C325.756 95.0192 325.663 95.2725 325.575 95.6419H325.577ZM308.898 83.7777C307.775 77.2786 303.057 73.2843 296.665 73.6096C290.552 73.921 285.127 78.8865 285.422 83.7777H308.898Z" />
      <path d="M263.568 109.184C257.299 118.257 244.433 123.3 230.165 119.482C216.612 115.857 208.094 103.905 208.476 89.2915C208.876 73.9977 217.639 62.9211 232.105 59.7889C245.789 56.8263 257.843 62.1125 263.475 70.6656C259.547 73.9419 255.608 77.2274 251.63 80.5502C250.544 79.5905 249.521 78.603 248.416 77.7224C241.322 72.0644 228.549 73.7606 225.903 85.9502C224.899 90.5742 225.441 95.061 228.05 99.0971C231.977 105.18 240.059 106.935 246.635 103.215C247.575 102.685 248.481 102.078 249.325 101.407C250.139 100.761 250.848 99.9847 251.76 99.118C255.669 102.45 259.591 105.791 263.568 109.182V109.184Z" />
      <path d="M364.329 104.432C364.329 109.012 364.343 113.417 364.299 117.823C364.297 118.113 363.902 118.622 363.641 118.657C357.351 119.477 351.059 119.891 344.958 117.611C339.301 115.497 336.189 111.157 335.057 105.41C334.515 102.664 334.257 99.8197 334.248 97.0175C334.18 77.7944 334.213 58.569 334.213 39.3436V37.975C334.683 37.9471 335.057 37.9076 335.434 37.9076C340.159 37.9029 344.883 37.9401 349.606 37.8797C350.683 37.8658 350.871 38.2376 350.869 39.2135C350.843 57.779 350.845 76.3468 350.859 94.9146C350.859 96.1856 350.904 97.4706 351.103 98.723C351.708 102.459 353.582 104.197 357.375 104.481C359.177 104.616 360.995 104.516 362.806 104.511C363.264 104.511 363.725 104.465 364.327 104.434L364.329 104.432Z" />
      <path d="M199.196 119.21H182.589V60.6719H199.196V119.21Z" />
      <path d="M100.72 119.187C100.557 119.215 100.413 119.259 100.266 119.259C94.9622 119.264 89.6584 119.261 84.2568 119.261V60.637H100.72V119.187Z" />
      <path d="M92.4417 31.8105C98.3199 31.8128 102.915 36.4484 102.912 42.3689C102.91 48.1547 98.1851 52.8878 92.4394 52.8553C86.5635 52.8228 81.8759 48.1361 81.8921 42.3131C81.9084 36.4252 86.5449 31.8082 92.4394 31.8128L92.4417 31.8105Z" />
      <path d="M180.35 42.2946C180.287 36.2764 185.349 31.5363 191.218 31.7826C196.003 31.9824 201.356 35.5724 201.333 42.3759C201.312 48.7147 196.506 52.7856 190.737 52.8297C184.944 52.8739 180.308 48.0896 180.347 42.2923L180.35 42.2946Z" />
    </g>
    <defs>
      <clipPath id="clip0_9447_74553">
        <rect width={364} height={154} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgDigicelWordmark);
export default Memo;
