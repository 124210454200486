import { styled } from '@mui/material';
import { ComponentType, SVGProps } from 'react';

import IconLookup from 'components/@icons';
import IconModifiedLookup from 'components/@iconsModified';

export type IconNames =
  | keyof typeof IconLookup
  | keyof typeof IconModifiedLookup;

type IconLookupType = Record<string, ComponentType<SVGProps<SVGSVGElement>>>;

interface Props extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: IconNames;
}

const Icon = ({ name, ...rest }: Props) => {
  const SvgIcon =
    (IconLookup as IconLookupType)[name] ||
    (IconModifiedLookup as IconLookupType)[name];

  return <SvgIcon {...rest} />;
};

export default styled(Icon)({});
